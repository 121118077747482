export const API_URL = {
  GET_ROOMINFO: "/sdk/v1/room/get-publish-info",
  CREATE_RECORD: '/api/v1/record/create',
  REPORT: ""
}


export const KV = {
  APP_ID: "app_id",
  THIRD_PARTY_USER_ID: "third_party_user_id",
  CLIENT: 'client',
  ACCESS_TOKEN: 'access_token',
  PACKAGE_CHECK: 'package_check',
}


export const Event = {
  START_PUSH: 'JA_PUBLISH',// 推流
  STOP_PUSH: 'JA_UNPUBLISH',//停止推流\
  ENV_DETECTION: 'JA_SYSTEM_CHECK',// 环境检测
  GET_DEVICES: 'JA_GET_DEVICES',// 获取设备列表
  SET_FULLSCREEN: 'JA_FULLSCREEN',// 设置全屏或者恢复
  SET_VOLUME: 'JA_VOLUME',// 设置声音 0-100
  SEND_DOCMESSAGE: 'JA_MERGE_DATA',//发送文档消息

  AJ_DEVICE_LIST: 'getDevicesCallback',//	设备列表
  AJ_SYSTEM_CHECK_RESULT: 'envDetectionCallback', //	系统检测内容回传
  AJ_MEDIA_CODE: 'mediaEvent'
}


export const LivePusherConstant = {
  VIDEO_PROFILE_480P_0: { w: "640", h: "480" },
  VIDEO_PROFILE_480P_1: { w: "848", h: "480" },
  VIDEO_PROFILE_720P_0: { w: "960", h: "720" },
  VIDEO_PROFILE_720P_1: { w: "1280", h: "720" },
  VIDEO_PROFILE_960P: { w: "1280", h: "960" },
  VIDEO_PROFILE_1080P: { w: "1920", h: "1080" },
}


