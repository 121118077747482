import { CONFIG } from '../common/Config'

/**
 * 加载base
 */
export async function loadMsgScript() {
  return new Promise((resolve, reject) => {
    if (document.getElementById("VhallBaseSDK")) {
      resolve(true)
      return;
    }
    let url = "";
    let scriptEl = document.createElement('script')
    scriptEl.setAttribute('type', 'text/javascript')
    scriptEl.setAttribute('id', "VhallBaseSDK");
    scriptEl.setAttribute('src', CONFIG.BASE_MESSAGE_URL)
    document.head.appendChild(scriptEl);
    scriptEl.onload = () => resolve(true);
    scriptEl.onerror = () => reject(false);
  });
}

export function getSwfUrl() {
  return "./Shy.swf"
}

export function isHashFlash() {
  var flashObj;
  if (typeof window.ActiveXObject != "undefined") {
    flashObj = new ActiveXObject("ShockwaveFlash.ShockwaveFlash");
  } else {
    flashObj = navigator.plugins['Shockwave Flash'];
  }
  return flashObj ? true : false;
}

export function filterDevices(devices, flag) {
  return devices.filter(function (item, index) {
    return item.kind === flag
  }).map(function (item, index) {
    return item.label
  })
}

export async function getDevice(type) {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const arr = devices.filter(item => { return item.kind === "videoinput" });
      navigator.getUserMedia({ audio: true, video: arr.length !== 0 }, stream => {
        resolve(devices);
        stream.getTracks().forEach(trackInput => { trackInput.stop() });
      }, err => {
        reject(err)
      });
    }).catch(err => {
      reject(err)
    })
  });
}



export function uuid(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [], i;
  radix = radix || chars.length;
  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    var r;
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('');
}