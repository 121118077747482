import SwfObject from 'swfobject'
// const SwfObject = require('./../../src/libs/swfobject')
import { isHashFlash, getSwfUrl } from './Utils'
export default new class SwfHelper {
  init(videoNode, callback) {
    if (!isHashFlash()) {
      document.getElementById(videoNode).innerHTML = `<embed  quality="high" width="100%" height="100%"  type="application/x-shockwave-flash" />`
      callback({ id: videoNode, ref: document.getElementById(videoNode), success: true });
      return;
    }

    const flashVersion = '10.0.0'
    const flashVars = '{}'
    const expressInstallUrl = ''
    const params = {
      quality: 'high',
      bgcolor: '#363636',
      allowscriptaccess: 'always',
      allowfullscreen: 'true',
      allowFullScreenInteractive: 'true',
      wmode: 'transparent'
    }
    const attributes = {
      styleclass: '',
      name: '',
      align: 'middle'
    }
    SwfObject.embedSWF(`${getSwfUrl()}?v=${Math.random()}`, videoNode, '100%', '100%', flashVersion, expressInstallUrl, flashVars, params, attributes, callback)

  }
}
