import { Event } from "./common/Contant";
import { filterDevices } from "./utils/Utils";
import API from "./message/API";
import Logreport from "./utils/Logreport";
export default class Interface {
  constructor(params, app) {

    this.app = app;
    this.videoNode = app.videoNode;
    this.roomId = params.roomId;
    this.streamUrl = app.streamUrl;
    this.msgInterface = app.msgInterface;
    this.videoArr = filterDevices(params.deviceData, "videoinput");
    this.audioArr = filterDevices(params.deviceData, "audioinput");
    this.isLoadScript = params.isLoadScript;
    this.api = new API(app.store);
    this.logreport = new Logreport(this);
    this.eventHandler = {
      startPushSuccess: function () { },
      startPushFailure: function () { }
    }
    this.isRepush = false;
    this.msgInterface.connect();

    window.ajMsg = this.ajMsg.bind(this);
  }


  /**
   * 开始推流
   * @param {object} opt 参数
   */
  startPush(opt, success, failure) {
    if (this.isPushing) {
      return false;
    }
    let params = null;
    if (opt.hasOwnProperty("profile")) {
      params = {
        cam: opt.video || "",
        mic: opt.audio || this.audioArr[0],
        width: !opt.profile ? VhallLivePusher.VIDEO_PROFILE_720P_1.w : opt.profile.w,
        height: !opt.profile ? VhallLivePusher.VIDEO_PROFILE_720P_1.h : opt.profile.h,
        server: this.streamUrl,
        stream: this.roomId
      }
    } else {
      params = {
        cam: opt.video || "",
        mic: opt.audio || this.audioArr[0],
        width: opt.width || "854",
        height: opt.height || "480",
        server: this.streamUrl,
        stream: this.roomId
      };
    }
    this.startPushParams = params;
    this.jaMsg(Event.START_PUSH, params);


    this.eventHandler.startPushSuccess = success;
    this.eventHandler.startPushFailure = failure;
  }

  /**
   * 停止推流
   */
  stopPush() {
    this.jaMsg(Event.STOP_PUSH, {});
    this.logreport.stopPush();
    this.isPushing = false;
  }

  /**
   * 获取设备
   * @param {string} type 类型
   */
  getDevices(type) {
    let result;
    switch (type) {
      case "video":
        result = this.videoArr;
        break;
      case "audio":
        result = this.audioArr;
        break;
      default:
        result = [];
        break;
    }
    return result;
  }

  /**
   * 设置音量 
   * @param {string} volume 音量
   */
  setVolume(volume) {
    this.jaMsg(Event.SET_VOLUME, { volume })
  }

  // createRecord(opt, success, failure) {
  //   if (!opt.hasOwnProperty("startTime") && !opt.hasOwnProperty("endTime")) {
  //     return;
  //   }
  //   const { startTime, endTime } = opt;
  //   this.api.createRecord(startTime, endTime).then(res => {
  //     console.error(res);
  //   }).catch(err => {
  //     console.error(err)
  //   });
  // }

  /**
   * 与flash通讯
   * @param {string} type 类型
   * @param {string} params 参数
   */
  jaMsg(type, params) {
    const flash = document.getElementById(this.videoNode);
    flash.jaMsg(type, JSON.stringify(params));
  }

  ajMsg(type, params) {
    if (type === 'AJ_MEDIA_CODE') {
      switch (params.code) {
        // 推流成功
        // case "NetConnection.Connect.Success":
        case "NetStream.Publish.Start":
          this.isPushing = true;  // 是否正在推流
          if (this.isRepush) {
            clearInterval(this.rePushTimer);
            this.isRepush = false;
          } else {
            this.logreport.startPush();
            this.eventHandler.startPushSuccess && this.eventHandler.startPushSuccess();
          }
          break;
        // 推流失败
        case "NetConnection.Connect.Failed":
        case "NetConnection.Connect.Closed":
          this.isRepush = true;
          this.isPushing = false; // 是否正在推流
          if (this.rePushTimer) {
            clearInterval(this.rePushTimer);
          }
          this.rePushTimer = setInterval(() => {
            this.jaMsg(Event.STOP_PUSH, {});
            this.startPushParams && this.jaMsg(Event.START_PUSH, this.startPushParams);
          }, 3000);
          this.eventHandler.startPushFailure({ code: '211003', msg: '网络错误', data: {} });
          break;
        case "NetStream.Publish.BadName":
        case "NetStream.Publish.AlreadyPublished":
          this.eventHandler.startPushFailure({ code: '211005', msg: '推流失败', data: {} });
          break;
        case "NetStream.Publish.AuthFailed":
          this.eventHandler.startPushFailure({ code: '211006', msg: '推流token验证失败', data: {} });
          break;
        case "NetStream.Publish.BlackList":
          this.eventHandler.startPushFailure({ code: '211007', msg: '流名在黑名单', data: {} });
          break;
        case "NetStream.Publish.NotWhiteList":
          this.eventHandler.startPushFailure({ code: '211008', msg: '流名不在白名单', data: {} });
          break;
        case "NetStream.Publish.KickOut":
          this.eventHandler.startPushFailure({ code: '211009', msg: '被管理者踢了', data: {} });
          break;
      }
    }
  }
}

