import Http from "./Http";
import { API_URL } from "../common/Contant"

export default class API {
  constructor(store) {
    this.store = store;
  }


  init() {
    return new Promise((resolve, reject) => {
      const http = new Http(this.store);
      http.fire(API_URL.INIT);
      http.onError = e => reject(e);
      http.onLoad = e => resolve(e);
    })
  }


  getRoomInfo(roomId) {
    return new Promise((resolve, reject) => {
      const http = new Http(this.store);
      http.fire(API_URL.GET_ROOMINFO, { room_id: roomId });
      http.onError = e => reject(e);
      http.onLoad = e => resolve(e);
    })
  }


  createRecord(startTime, endTime) {
    return new Promise((resolve, reject) => {
      const http = new Http(this.store);
      http.fire(API_URL.CREATE_RECORD, {
        start_time: startTime,
        end_time: endTime,
        signed_at: Math.round(new Date().getTime() / 1000)
      });
      http.onError = e => reject(e);
      http.onLoad = e => resolve(e);
    })
  }

  report(obj) {
    return new Promise(() => {
      let http = new Http(this.store)
      http.fire(API_URL.REPORT, obj, 'report', 'GET')
    });
  }

}