
import API from "./message/API";
import Store from "./common/Store";
import Interface from "./Interface";
import { loadMsgScript } from "./utils/Utils";
import { KV, LivePusherConstant } from "./common/Contant";
import SwfHelper from "./utils/Swfhelper";
import { getDevice } from "./utils/Utils";
window.VhallLivePusher = new class VhallLivePusher {

  constructor() {
    this.streamUrl = "";
    for (let i in LivePusherConstant) {
      this[i] = LivePusherConstant[i];
    }
  }

  /**
   * 创建实例
   * @param {object} opt 创建实例所需参数
   * @param {function} success 成功回调
   * @param {function} failure 失败回调
   */
  async createInstance(opt, success, failure) {
    const { appId, accountId, token, roomId, videoNode } = opt;
    const isLoadScript = await loadMsgScript();
    if (!document.getElementById(videoNode)) {
      failure && failure({ code: '211001', msg: "该节点不存在", data: {} });
      return;
    }

    this.videoNode = videoNode;
    this.store = new Store();
    this.store.setKV(KV.APP_ID, appId);
    this.store.setKV(KV.THIRD_PARTY_USER_ID, accountId);
    this.store.setKV(KV.CLIENT, 'js');
    this.store.setKV(KV.ACCESS_TOKEN, token);
    this.store.setKV(KV.PACKAGE_CHECK, "peter");
    this.api = new API(this.store);
    try {
      const result = await this.api.getRoomInfo(roomId);
      const { publish_args, publish_domainname } = result.publish_server;
      const { accesstoken, mixer, vhost } = publish_args;
      this.streamUrl = `${publish_domainname[0]}?vhost=${vhost}?token=${publish_args.token}?webinar_id=${roomId}?ismix=0?mixserver=?accesstoken=${accesstoken}/${roomId}`;
    } catch (error) {
      failure && failure(error);
      return;
    }

    SwfHelper.init(videoNode, async res => {
      if (res.success) {
        let deviceData = [];
        try {
          deviceData = await getDevice();
        } catch (error) {
          failure && failure({ code: '211003', msg: '获取推流设备出错', data: {} });
          return;
        }
      

        if (isLoadScript) {
          VhallMsg.createInstance({ appId, third_party_user_id: accountId, token, channelId: roomId }, msg => {
            this.msgInterface = msg;
            success && success({ interface: new Interface({ roomId, deviceData, isLoadScript }, this) });
          }, err => {
            failure && failure(err);
          });
        }



      } else {
        failure && failure({ code: '21102', msg: "flash初始化失败", data: {} });
      }
    });
  }
}

