/** 生产环境api配置 */
const PROD_CONFIG = {
  API_SERVER: '//api.vhallyun.com',
  REPORT_SERVER: '//dc.e.vhall.com/login',
  BASE_MESSAGE_URL: `https://static.vhallyun.com/jssdk/vhall-jssdk-base/vhall-msg-1.0.2.js?t=${Date.now()}`
};

/** 开发环境api配置 */
const DEVELOP_CONFIG = {
  API_SERVER: '//t-open.e.vhall.com',
  REPORT_SERVER: '//t-dc.e.vhall.com/login',
  BASE_MESSAGE_URL: `https://t-static01-open.e.vhall.com/jssdk/vhall-jssdk-base/vhall-msg-1.0.2.js?t=${Date.now()}`
};

export const CONFIG =
  process.env.NODE_ENV === 'production' ? PROD_CONFIG : DEVELOP_CONFIG;
