import { uuid } from "./Utils";

export default class Logreport {
  constructor(app) {
    this.api = app.api;
    this.roomId = app.roomId;
    this.streamUrl = app.streamUrl;
    this.startPushTime;
  }

  startPush() {
    const token = {
      ua: window.navigator.userAgent,
      p: this.roomId,//roomId
      aid: this.roomId,
      sd: this.streamUrl.match(/([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}/)[0],//推流服务器
      _r: {
        w: '',
        h: ''
      },// 分辨率
      pf: '3', //平台
      dev: {
        camera: '',
        mic: ''
      } //设备
    }
    const params = {
      k: 82001,
      token: window.btoa(JSON.stringify(token)),
      bu: "1",
      id: new Date().getTime(),
      s: uuid(8, 16),
    }
    this.api.report(params);
    this.startPushTime = new Date().getTime();
  }


  stopPush() {
    const token = {
      ua: window.navigator.userAgent,
      p: this.roomId,//roomId
      sd: this.streamUrl.match(/([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}/)[0],//推流服务器
      tt: new Date().getTime() - this.startPushTime,//推流时长
    }
    const params = {
      k: 82002,
      token: window.btoa(JSON.stringify(token)),
      bu: "1",
      id: new Date().getTime(),
      s: uuid(8, 16),
    }
    this.api.report(params);
  }
}