export default class Store {
  constructor() {
    this.info = {}
  }

  /**
   * 根据KV价值对进行存储
   *
   * @param {*} key
   * @param {*} value
   * @memberof Store
   */
  setKV(key, value) {
    this.info[key] = value
  }

  /**
   * 根据key获取
   *
   * @param {*} key
   * @returns
   * @memberof Store
   */
  getKV(key) {
    return this.info[key]
  }

  /**
   * 根据key删除键值对
   *
   * @param {*} key
   * @memberof Store
   */
  deleteKey(key) {
    if (this.info.hasOwnProperty(key)) {
      delete this.info[key]
    }
  }

  /**
   * 重置键值对
   *
   * @memberof Store
   */
  reset() {
    this.info = {}
  }

  toString() {
    let keys = Object.keys(this.info);
    let tmp = '';
    keys.forEach(key => {
      tmp += `${key}:${this.info[key]} `
    });
    return tmp
  }
}
